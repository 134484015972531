<style lang="scss">
@import "~@/assets/css/var";
.terminal-table {
    border-radius: 20px;
    box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px 0;
    .ts-table {
        margin-top: 0 !important;
    }
    .my-table {
        padding: 0;
        .el-table{
            width: 100% !important;
        }
    }
    &__title{
        margin: 0 20px;    
        font-size: 14px;
        .title{
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    &-content{
        margin: 0 20px;    
        display: flex;
        justify-content: space-between;
        &_left{
            width: 75%;
            .canvas{
                margin: 0;
                height: 300px;
                width: 100%;
            }
            .echart{
                margin-top: 20px;
            }
        }
        &_right{
            width: 25%;
            .make-table{
                padding-left: 10px;
                height: 100%;
                .el-table{
                    height: 96%;
                }
            }
        }
    }
}
</style>

<template>
    <div class="terminal-table">
        <div class="terminal-table__title">
            <div class="title">终端医院历年销售数据</div>
            <div style="display: flex; align-items: center;">
                <div>筛选</div>
                <el-input style="width: 150px; margin-left: 10px;" v-model="comNameLike" placeholder="请输入药品名称"></el-input>
                <el-button style="margin-left: 10px;" type="primary" @click="onSearch">查询</el-button>
            </div>
        </div>
        <div class="terminal-table-content">
            <div class="terminal-table-content_left">
                <ts-table class="make-table" :cols="cols" hide-border :data="list" 
                    :page-param="pageParam" :loading="loading" hide-detail :height="null" 
                    @handleSizeChange="handleSizeChange" @handleSort="sort" stop-empty-hide
                    :cmds="cmds" @handleCurrentChange="handleCurrentChange" :pageSizes="pageSizes">
                    <template v-slot:slot_price="{props}">
                        <div class="flex flex-center" style="justify-content:center">
                            <div class="flex clickable" @click="showCanvas(props.row)" style="justify-content:center;margin-right: 5px;">
                                <svg style="width: 20px;height: 20px" t="1673858334212" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="1514" width="128" height="128">
                                    <path d="M124.928 660.48h104.96v238.08h-104.96zM347.136 535.552h104.96v363.52h-104.96zM548.864 644.608h104.96v254.464h-104.96zM766.976 422.4h104.96v476.672h-104.96z"
                                        :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1515"></path>
                                    <path d="M666.112 124.928l110.592 118.784L535.04 473.6 351.744 307.2v-0.512l-0.512-0.512-1.536 2.048L61.44 536.576l32.768 41.472 254.976-202.752 186.368 169.472 1.024-1.024 0.512 0.512 275.456-262.144 100.864 108.544 12.8-260.096z"
                                        :fill="props.row.clicked ? '#F11C1C' : '#5171DA'" p-id="1516"></path>
                                </svg>
                            </div>
                        </div>
                    </template>
                </ts-table>
                <div class="echart" v-loading="otherTable.loading">
                    <div ref="canvas" :id="canvasId" class="canvas"></div>
                </div>
            </div>
            <div class="terminal-table-content_right">
                <ts-table class="make-table" :cols="otherTable.cols" hide-border 
                    :data="otherTable.list" hide-page :loading="otherTable.loading"
                    hide-detail :height="null" @handleSort="sortOtherTable">
                </ts-table>
            </div>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    data() {
        return {
            canvasId: 'terminal-table-area-echart',
            loading: false,
            stop: true,
            pageParam: {
                pageNo: 1,
                pageSize: 5,
                total: 0,
            },
            pageSizes: [5, 10, 20, 50, 100],
            defalutSize: 5,
            currentType: '',
            conditionList: [],
            list: [],
            selectOne: {},
            cols: this.$help.generalCols([
                {
                    fieldName: 'provinceName',
                    name: '省份',
                    width: 40,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'hospitalName',
                    name: '医院',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'hospitalLevel',
                    name: '等级',
                    width: 40,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'comName',
                    name: '药品名称',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 40,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'packUnit',
                    name: '包装规格',
                    width: 60,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'price',
                    name: '趋势图',
                    width: 40,
                    listQueryView: '是',
                    listQueryModel: 1,
                }
            ]),
            cmds: [],   
            years: [],

            comNameLike: '',

            //不翻页表格
            otherTable: {
                list: [],
                loading: false,
                cols:  this.$help.generalCols([{
                    fieldName: 'saleYear',
                    name: '年份',
                    width: 50,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'quantity',
                    name: '销售数量',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'amount',
                    name: '销售金额',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                },
                {
                    fieldName: 'rate',
                    name: '增长比',
                    width: 60,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listStipulate: 1
                }])
            },

            echartTypeList: [{
                key: 'quantity',
                value: '销售数量',
                position: 'left',
                color: '#5B8FF9'
            },
            {
                key: 'amount',
                value: '销售金额',
                position: 'left',
                color: '#C15350'
            },]
        }
    },

    created() {
        this.getYearList()
    },
    mounted() {
        this.$nextTick(()=>{
            this.renderArea()
        })
    },

    methods: {
        getYearList() {
            this.$api.get('setting/year/terminalYearList').then(res=>{
                let years = []
                if(res.data) {
                    for(let i in res.data) {
                        years.push({
                            key: i,
                            value: res.data[i].year,
                            keyYear: res.data[i].year
                        })
                    }
                }
                this.years = years
                let yearCols = this.$help.generalCols(this.getYearCols({
                    listOrderView: '否'
                }))
                this.cols.splice(6, 0, ...yearCols);
            }).finally(this.initCustom)
        },

        getYearCols(obj = {}) {
            return this.years.map(item => {
                return this.$help.joinObj({
                    fieldName: 'amount' + item.keyYear,
                    name: item.value + '年',
                    listOrderView: '是',
                    width: 60,
                    listClass: 'tc'
                }, obj)
            })
        },

        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },

        getCustomList() {
            this.loading = true
            this.$api.post(`terminal/saleTerminal/saleTerminalPage`, {
                conditionList: this.conditionList,
                page: this.pageParam,
                // sort: this.sortParam ? this.sortParam : this.initSort
            }).then(res => {
                if (res.success) {
                    this.pageParam.total = res.data.totalCount
                    this.list = res.data.records
                    if(this.list.length !== 0) {
                        this.getTrendList(this.list[0])
                        this.selectOne = this.list[0]
                        this.$emit('sendToInv', this.selectOne)
                    }else {
                        this.$emit('sendToInv')
                        this.selectOne = {}
                        this.otherTable.list = []
                        this.$nextTick(()=>{
                            this.renderArea()
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },

        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },

        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },

        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.initCustom()
        },

        onSearch() {
            let conditionList = []; 
            if(this.comNameLike) {
                conditionList.push({
                    name: "comName",
                    op: "like",
                    value: this.comNameLike
                })
            }
            this.conditionList = conditionList
            this.initCustom()
        },

        //不翻页表格
        getTrendList(data) {
            this.otherTable.loading = true
            let list = []
            this.$api.get(`terminal/saleTerminal/saleTerminalComAmountAndRate`, {
                comCode: data.comCode
            }).then(res => {
                if (res.success) list = res.data
                this.otherTable.list = list
                this.$nextTick(()=>{
                    this.renderArea()
                })
            }).finally(() => {
                this.otherTable.loading = false
            })
        },
        showCanvas(row) {
            this.getTrendList(row)
            this.selectOne = row
            this.$emit('sendToInv', this.selectOne)
        },
        //echart
        renderArea() {
            let option = {};
            if(this.otherTable.list.length > 0) {
                let xAxisData = [], seriesData = [], legendData = [];
                this.otherTable.list.forEach(item=>{
                    xAxisData.push(item.saleYear)
                })
                this.echartTypeList.forEach(item=>{
                    let data = []; legendData.push(item.value);
                    this.otherTable.list.forEach(child=>{
                        data.push(child[item.key])
                    })
                    if(item.position === 'left') {
                        seriesData.push({
                            name: item.value,
                            data: data,
                            type: 'line',
                            smooth: true,
                            color: item.color, //线条颜色
                            connectNulls: true,
                            areaStyle: {
                                normal: {
                                    color: {
                                        type: "linear",  x: 0, y: 0, x2: 0, y2: 1,
                                        colorStops: [{
                                            offset: 0,
                                            color: item.color
                                        },
                                        {
                                            offset: 1,
                                            color: "#fff"
                                        }],
                                        global: false, // 缺省为 false
                                        // legend: {
                                        //     textStyle: {
                                        //         color: "#C15350",
                                        //         fontSize: 16
                                        //     }
                                        // }
                                    }
                                }
                            },
                            itemStyle:{
                                normal: {
                                    label: {
                                        show: true
                                    }
                                }
                            }
                        })
                    }else {
                        seriesData.push({
                            name: item.value,
                            type: 'line',
                            smooth: true,
                            connectNulls: true,
                            color: item.color, //线条颜色
                            data: data,
                        })
                    }
                })
                option = {
                    title: {
                        show: true,
                        text: `${this.selectOne.hospitalName}-${this.selectOne.comName}`,
                        left: 10,
                        textStyle: {
                            fontSize: 15
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        boundaryGap: true,
                    },
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}'
                            }
                        },
                        // {
                        //     type: 'value',
                        //     axisLabel: {
                        //         formatter: '{value}%'
                        //     }
                        // }
                    ],
                    legend: {
                        data: legendData,
                        right: 20
                    },
                    grid: {
                        left: 20, 
                        right: 20,
                        bottom: 20,
                        containLabel: true
                    },
                    series: seriesData
                };
            }else {
                option = {
                    title: {
                        show: true,
                        text: '暂无数据',
                        left: 'center',
                        top: 'center'
                    },
                }
            }
            const myChart = window.echarts.init(document.getElementById(this.canvasId))
            myChart.setOption(option, true)
            window.addEventListener('resize', function () {
                myChart.resize()
            })
        },

        sortOtherTable() {

        }
    }
}
</script>