<style lang="scss">
@import "~@/assets/css/var";

.terminal-inv-table {
    border-radius: 20px;
    box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px 0;

    .ts-table {
        margin-top: 0 !important;
    }

    .my-table {
        padding: 0;

        .el-table {
            width: 100% !important;
        }
    }

    &__title {
        margin: 0 20px;
        font-size: 14px;

        .title {
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    &-content {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;

        &_left {
            width: 60%;
            .make-table {
                height: 100%;
            }
        }

        &_right {
            width: 40%;
            .echart{
                padding-left: 10px;
                .canvas {
                    margin: 0;
                    height: 400px;
                    width: 100%;
                }
            }
        }
    }
}
</style>

<template>
    <div class="terminal-inv-table">
        <div class="terminal-inv-table__title">
            <div class="title">药品覆盖</div>
            <div style="display: flex; align-items: center;">
                <div>筛选</div>
                <el-select style="width: 150px; padding: 0 10px;" v-model="areaObj.value" 
                    filterable remote reserve-keyword placeholder="请输入省份名称" 
                    :remote-method="remoteMethodComArea" clearable :loading="areaObj.loading">
                    <el-option v-for="item in areaObj.options" :key="item.value" 
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select style="width: 150px; padding: 0 10px;" v-model="comObj.value" 
                    filterable remote reserve-keyword placeholder="请输入药品名称" 
                    :remote-method="remoteMethodCom" clearable :loading="comObj.loading">
                    <el-option v-for="item in comObj.options" :key="item.value" 
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button style="margin-left: 10px;" type="primary" @click="onSearch">查询</el-button>
            </div>
        </div>
        <div class="terminal-inv-table-content">
            <div class="terminal-inv-table-content_left">
                <ts-table class="make-table" :cols="cols" hide-border 
                    :data="list" hide-page :loading="loading" hide-detail
                    :height="null" stop-empty-hide>
                    <template v-slot:slot_proportion="{props}">
                        <div class="flex flex-center">
                           {{ props.row.proportion}}%
                        </div>
                    </template>
                </ts-table>
            </div>
            <div class="terminal-inv-table-content_right">
                <div class="echart">
                    <div ref="canvas" :id="canvasId" class="canvas"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
// import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            canvasId: 'terminal-table-pie-echart',
            loading: false,
            stop: true,
            list: [],

            cols: this.$help.generalCols([
                {
                    fieldName: 'levelBigTypeName',
                    name: '医院等级',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listClass: 'tc',
                    listOrderView: '是',
                    operateType: 'select',
                    onDisplay: false,
                    onRequired: true,
                },
                {
                    fieldName: 'terminalNumber',
                    name: '现有终端数据量',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                },
                {
                    fieldName: 'saleTerminalNumber',
                    name: '药品覆盖量',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                },
                {
                    fieldName: 'proportion',
                    name: '覆盖率',
                    width: 80,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                },
            ]),
            cmds: [],

            comObj: {
                value: '',
                loading: false,
                options: []
            },
            areaObj: {
                value: '',
                loading: false,
                options: []
            }
        }
    },

    created() {},
    mounted() {
        this.$nextTick(() => {
            this.renderPie()
        })
        // this.remoteMethodCom('', true)
        this.remoteMethodComArea('')
    },

    methods: {
        //药品
        remoteMethodCom(query = '') {
            this.comObj.value = ''
            this.comObj.loading = true
            this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {
                keyword: query
            }).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.comCode,
                            label: ele.comName
                        })
                    })
                }
                this.comObj.options = options
                this.$nextTick(()=>{
                    if(options.length !== 0) {
                        this.comObj.value = options[0].value
                    }
                })
            }).finally(()=>{
                this.comObj.loading = false
            })
        },
        //省份
        remoteMethodComArea(query) {
            this.areaObj.loading = true
            this.$api.get('setting/sysBaseArea/querySysBaseAreaListByKeyword', {
                keyword: query
            }).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.id,
                            label: ele.fullName
                        })
                    })
                }
                this.areaObj.options = options
            }).finally(()=>{
                this.areaObj.loading = false
            })
        },

        getCustomList() {
            this.loading = true
            let api = this.areaObj.value 
            ? `terminal/saleTerminal/drugCoverageTerminal?provinceCode=${this.areaObj.value}` 
            : 'terminal/saleTerminal/drugCoverageTerminal';
            this.$api.post(api, {
                conditionList: [{
                    name: "comCode",
                    op: "eq",
                    value: this.comObj.value
                }]
            }).then(res => {
                if (res.success) {
                    this.list = res.data
                    this.$nextTick(() => {
                        this.renderPie()
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },

        sendValue(data) {
            if(data) {
                this.comObj.options = [{
                    value: data.comCode,
                    label: data.comName
                }]
                this.$nextTick(()=>{
                    this.areaObj.value = data.provinceCode
                    this.comObj.value = data.comCode
                    this.getCustomList()
                })
            }else {
                this.remoteMethodCom()
            }
        },

        onSearch() {
            this.getCustomList()
        },

        //echart
        renderPie() {
            let option = {};
            if(this.list.length !== 0) {
                let seriesData = []
                this.list.forEach(item=>{
                    seriesData.push({
                        value: item.saleTerminalNumber,
                        name: item.levelBigTypeName,
                        proportion: item.proportion
                    })
                })
                option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            type: 'pie',
                            center: ['60%', '50%'],
                            radius: '50%',
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: (colors) => {
                                        let colorList = [
                                            '#666FE8', '#878FF9', '#91cd77', '#ef6567', '#f9c956', 
                                            '#75bedc', '#2B9A99', '#FEA8CC', '#FFA054', '#72CAED', 
                                            '#F4664A', '#F4664A', '#2A42D8', '#2B9A99', '#c23531', 
                                            '#2f4554', '#61a0a8', '#666FE822', '#878FF944', '#f9c95666'
                                        ]
                                        return colorList[colors.dataIndex % 16]
                                    }
                                }
                            },
                            label: {
                                formatter(param) {
                                    let str = `${param.name}\n${param.data.proportion}%`
                                    return str
                                },
                                minMargin: 5,
                                lineHeight: 15,
                                rich: {
                                    time: {
                                        fontSize: 10,
                                        color: '#666'
                                    }
                                }
                            },
                        }
                    ]
                };
            }else { 
                option = {
                    title: {
                        show: true,
                        text: '暂无数据',
                        left: 'center',
                        top: 'center'
                    },
                }
            }
            const myChart = window.echarts.init(document.getElementById(this.canvasId))
            myChart.setOption(option, true)
            window.addEventListener('resize', function () {
                myChart.resize()
            })
        }
    }
}
</script>