<template>
    <div class="common-table search-result">
        <div class="common-table__title">终端销售
        </div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div class="search-result__container" style="background: unset;" v-if="loaded && !isEmpty">
            <div style="margin-top: 20px;">
                <terminal-table @sendToInv="sendToInv"></terminal-table>
            </div>
            <div style="margin-top: 20px;">
                <terminal-inv-table ref="terminalInvTable"></terminal-inv-table>
            </div>
        </div>
    </div>
</template>

<script>
    import TerminalTable from './TerminalTable.vue'
    import TerminalInvTable from './TerminalInvTable.vue'
    export default {
        components: { TerminalTable, TerminalInvTable },
        data() {
            return {
                visitId: 0,
                pageId: '',
                pageName: '',
                tab: 'year',
                loaded: true,
                finished: {
                    year: false,
                    top: false,
                    amount: false,
                },
                isEmpty: false,
            }
        },
        created() {
            this.init()
        },
        watch: {
            '$route': function () {
                this.init()
            },
            tab: {
                handler(val) {
                    this.finished[val] = true

                    this.visitId = new Date().getTime()
                    this.pageId = val

                    this.$help.socket.send(this)
                },
                immediate: true
            },
        },
        methods: {
            init() {
                this.isEmpty = false
            },
            sendToInv(data) {
                this.$refs.terminalInvTable && this.$refs.terminalInvTable.sendValue(data)
            }
        },
    }
</script>

